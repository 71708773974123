import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    sidebarShow: true,
    activeView: localStorage.getItem('activeView') || 'skin',
};

const sidebarSlice = createSlice({
    name: 'sidebar',
    initialState,
    reducers: {
        setSidebarShow: (state, action) => {
            state.sidebarShow = action.payload;
        },
        setSidebarUnfoldable: (state, action) => {
            state.sidebarUnfoldable = action.payload;
        },
    },
});

const activeViewSlice = createSlice({
    name: 'activeView',
    initialState,
    reducers: {
        setActiveView: (state, action) => {
            localStorage.setItem('activeView', action.payload);
            state.activeView = action.payload;
        },
    },
});

export const { setSidebarShow, setSidebarUnfoldable } = sidebarSlice.actions;
export const { setActiveView } = activeViewSlice.actions;

export const sidebarReducer = sidebarSlice.reducer;
export const activeViewReducer = activeViewSlice.reducer;
