import { configureStore } from '@reduxjs/toolkit';
import { sidebarReducer, activeViewReducer } from './services/sidebarSlice';
import { skinDashboardApi } from './services/skinDashboardApiSlice';
import analyticsReducer from '../src/services/analyticsSlice';
import dateRangeReducer from '../src/services/dateRangeSlice';

const store = configureStore({
    reducer: {
        sidebar: sidebarReducer,
        activeView: activeViewReducer,
        [skinDashboardApi.reducerPath]: skinDashboardApi.reducer,
        analytics: analyticsReducer,
        dateRange: dateRangeReducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(skinDashboardApi.middleware),
});

export default store;