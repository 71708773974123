import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    analyticsData: null,
    loading: true,
    error: null,
};

const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        setData: (state, action) => {
            state.analyticsData = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setError: (state, action) => {
            state.error = action.payload;
        },
    },
});

export const { setData, setLoading, setError } = analyticsSlice.actions;

export default analyticsSlice.reducer;
