import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import Cookies from 'js-cookie';

const getBearerToken = () => {
    return Cookies.get('auth_token');
};

export const skinDashboardApi = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BASE_URL}`,
        prepareHeaders(headers) {
            const token = getBearerToken();
            headers.set('Authorization', `Bearer ${token}`);
            return headers;
        },
    }),

    endpoints: (builder) => ({
        // skin
        fetchAnalyticsData: builder.query({
            query: ({ type, storeNumbers, startDate, endDate }) =>
                `analytics/${type}/Dashboard?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),
        getProfile: builder.query({
            query: () => 'admin/getProfile',
        }),
        getUserFeedback: builder.query({
            query: ({ storeNumbers, type }) => `admin/userFeedback?storeNumbers=${storeNumbers}&feedbackOf=${type}`,
        }),
        getLiveSkus: builder.query({
            query: () => 'admin/getLiveSkus',
        }),
        fetchTotalUsers: builder.query({
            query: ({ storeNumbers, startDate, endDate }) =>
                `analytics/skin/TotalUsers?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),
        fetchTotalSessions: builder.query({
            query: ({ storeNumbers, startDate, endDate }) =>
                `analytics/skin/totalSessions?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),
        fetchTotalUserDropCount: builder.query({
            query: ({ storeNumbers, startDate, endDate }) =>
                `analytics/skin/totalUserDropCount?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),
        fetchTotalAvgMinutes: builder.query({
            query: ({ storeNumbers, startDate, endDate }) =>
                `analytics/skin/totalAvgMinutes?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),
        fetchTotalTopTen: builder.query({
            query: ({ storeNumbers, startDate, endDate }) =>
                `analytics/skin/totalTopTen?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),
        fetchTotalSmsWhatsapp: builder.query({
            query: ({ storeNumbers, startDate, endDate }) =>
                `analytics/skin/totalSmsWhatsapp?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),
        fetchTotalRecommendation: builder.query({
            query: ({ storeNumbers, startDate, endDate }) =>
                `analytics/skin/totalRecommendation?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),
        fetchTotalSkinConcern: builder.query({
            query: ({ storeNumbers, startDate, endDate }) =>
                `analytics/skin/totalSkinConcern?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),
        fetchTotalAgeGroup: builder.query({
            query: ({ storeNumbers, startDate, endDate }) =>
                `analytics/skin/totalAgeGroup?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),
        fetchTotalSkinType: builder.query({
            query: ({ storeNumbers, startDate, endDate }) =>
                `analytics/skin/totalSkinType?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),
        getMirrorStatus: builder.query({
            query: () => 'admin/getMirrosStatus',
        }),

        //makeup
        fetchMakeupTotalUsers: builder.query({
            query: ({ storeNumbers, startDate, endDate }) =>
                `analytics/makeup/totalUser?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),
        fetchMakeupTotalWishlistLook: builder.query({
            query: ({ storeNumbers, startDate, endDate }) =>
                `analytics/makeup/totalWishlistLook?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),
        fetchMakeupTop20: builder.query({
            query: ({ storeNumbers, startDate, endDate }) =>
                `analytics/makeup/top20?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),
        fetchMakeupAvgPresentMakeup: builder.query({
            query: ({ storeNumbers, startDate, endDate }) =>
                `analytics/makeup/avgPresentMakeup?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),
        fetchMakeupTotalUserDistribution: builder.query({
            query: ({ storeNumbers, startDate, endDate }) =>
                `analytics/makeup/totalUserDis?storeNumbers=${storeNumbers}&startDate=${startDate}&endDate=${endDate}`,
        }),

    }),
});

export const {
    useFetchAnalyticsDataQuery,
    useGetProfileQuery,
    useGetUserFeedbackQuery,
    useGetLiveSkusQuery,
    useGetMirrorStatusQuery,
    useFetchTotalUsersQuery,
    useFetchTotalSessionsQuery,
    useFetchTotalUserDropCountQuery,
    useFetchTotalAvgMinutesQuery,
    useFetchTotalTopTenQuery,
    useFetchTotalSmsWhatsappQuery,
    useFetchTotalRecommendationQuery,
    useFetchTotalSkinConcernQuery,
    useFetchTotalAgeGroupQuery,
    useFetchTotalSkinTypeQuery,

    useFetchMakeupAvgPresentMakeupQuery,
    useFetchMakeupTop20Query,
    useFetchMakeupTotalUserDistributionQuery,
    useFetchMakeupTotalUsersQuery,
    useFetchMakeupTotalWishlistLookQuery,
} = skinDashboardApi;